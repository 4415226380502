.pageInput {
    width: 84px;
    border: none;
    color: var(--text-color);
    background-color: var(--lightest-gray);
    height: 30px;
}

.totalpages {
    margin: 0 10px 0 10px;
    font-weight: bold;
}

.currentPage {
    padding-left: 10px;
    border-right: 1px solid var(--input-border);
}

.customPages {
    padding-top: 3px;
    border: 1px solid var(--input-border);
}


/* --=========== New CSS ============-- */

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    margin-left: 0;
    border-left: 1px solid var(--input-border);
}

.page-item:first-child .page-link {
    margin-left: 0;
}

.page-link {
    position: relative;
    display: block;
    padding: .4rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: var(--black);
    background-color: var(--white);
    border: 1px solid var(--input-border);
}

.page-number ul li a,
.page-number ul li .page-link {
    height: 25px !important;
    margin: 0;
    width: auto;
    text-align: center;
    padding: 2px 8px 3px;
    float: left;
    line-height: 18px;
    border: 1px solid var(--input-border);
    font-size: 15px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0px 0px var(--lightest-primary);
    /* padding: 0 3px; */
}

.page-number input[type="text"] {
    padding: 0 3px;
    height: 23px;
    width: 50px;
}

.customPages {
    padding-top: 0px;
    border: 1px solid var(--input-border);
}

.currentPage {
    padding-left: 2px;
    border-right: 1px solid var(--input-border);
    height: 30px !important;
    display: inline-block;
    line-height: 20px;
}

.currentPage span.searchIcon {
    padding: 0px 10px;
    font-size: 14px;
}

.page-number .totalpages {
    margin: 0 10px 0 10px;
    font-weight: normal;
    font-size: 13px;
}

.page-item:last-child .page-link {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.page-link:hover {
    background-color: var(--primary-color);
    border-color: var(--primary-color) !important;
    color: var(--white) !important;
}
.currentPage span.searchIcon.disabled-class {
    cursor: not-allowed;
    background: var(--light-gray);
    color: var(--lightest-secondary);
    padding: 5px 10px;
}
.page-item.disabled .page-link {
	color: var(--gray);
	pointer-events: none;
	cursor: auto;
	background-color: var(--lightest-gray);
	border-color: var(--input-border);
}

/* --=========== End New CSS ============-- */