.bold{
    font-weight: 600;
}

.no_pie {
	margin: 0 auto;
	color: var(--light-gray);
	width: calc(100% - 64px);
	text-align: center;
}
.no_pie * {
    color: var(--gray);
}
.parent_card {
	background-color: var(--lightest-gray);
	padding: 10px;
	border-radius: 10px;
	border: 1px solid var(--light-gray);
}
.childCard_wrap {
	position: relative;
}
.childCard_wrap::after {
	width: 1px;
	height: calc(100% - 18px);
	background-color: var(--light-gray);
	position: absolute;
	left: 25px;
	bottom: 30px;
	content: '';
	display: block;
}
.child_card {
	padding: 7px 10px;
	border: 1px solid var(--lightest-gray);
	background-color: var(--white);
	box-shadow: 0 0 5px var(--light-gray);
	border-radius: 10px;
	margin-top: 10px;
	margin-left: 45px;
	position: relative;
	width: calc(100% - 45px);
}
.child_card::before {
	width: 20px;
	height: 1px;
	background-color: var(--light-gray);
	position: absolute;
	right: 100%;
	top: 50%;
	content: '';
	display: block;
}
