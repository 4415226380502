
.configuration-list li a {
  display: flex;
}
.configuration-list li a img {
  width: 24px;
  margin-right: 5px;
}
.heightTable {
  height: calc(100vh - 115px);
}

.table_items_list,
.accordian_items_list {
  height: calc(100vh - 130px);
  border: 0 none;
}

.fs14 {
  font-size: 14px;
}

.bg-mono {
  background-color: var(--light-primary);
}

.bg-monoz-light {
  border: 1px solid var(--card-border);
  border-radius: 0.7rem;
  background-color: var(--white);
}

.group_name {
  border: 1px solid var(--primary-color);
}

.tb_width {
  width: 98%;
}

.fs13 {
  font-size: 14px;
}

.fs12 {
  font-size: 13px;
}

.textHead {
  color: var(--label-color);
  font-size: 14px;
  font-weight: 600;
}

.tableAccordian-switch .form-check-input {
  opacity: 0;
}

.tableAccordian-switch label {
  border: 1px solid var(--body-background);
  margin: 0 10px;
  display: block;
  border-radius: 4px;
  overflow: hidden;
}

.tableAccordian-switch label svg {
  padding: 5px;
  cursor: pointer;
  background-color: var(--light-gray);
  border: 1px solid var(--light-gray);
  width: auto;
  height: 30px;
  fill: var(--text-color);
  transition: all 0.3s linear;
}

.tableAccordian-switch input:not(:checked)+label span:nth-child(2) svg,
.tableAccordian-switch input:checked+label span:first-child svg {
  background-color: var(--primary);
  cursor: default;
  border: 1px solid var(--primary);
  color: var(--white-color);
}

.tableAccordian-switch input+label span:nth-child(2) svg {
  border-radius: 0 5px 5px 0;
}

.tableAccordian-switch input+label span:first-child svg {
  border-radius: 5px 0 0 5px;
}

.prevnext {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 55px;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 57px);
  background: var(--white);
  padding: 10px;
  align-items: center;
}

.label-style.input-group-text {
  font-size: 1em;
  min-width: 176px;
  margin: 0;
}

.input-group-text.bg-light.label-style {
  min-width: 100px;
}

.editIcon {
  position: absolute;
  z-index: 100;
  right: 78px;
  top: 35%;
}

.deleteIcon {
  position: absolute;
  z-index: 100;
  right: 50px;
  top: 35%;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--white);
}

.btn-radioCheckbox input[type="radio"]+label {
  padding: 0 0 0 29px !important;
  border-radius: 4px;
  display: block;
  margin: 0;
  width: 100%;
  line-height: 1.1;
}

.btn-radioCheckbox input[type="radio"]+label::before {
  left: 8px;
  top: 2px;
}

.btn-radioCheckbox input[type="radio"]+label::after {
  left: 11px;
  top: 5px;
  content: "\f111";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 8px;
  line-height: 9px;
  color: var(--white);
  background: transparent;
}

.input-group .btn-radioCheckbox input[type="radio"]+label:not(:last-child) {
  border-radius: 0;
}

.input-group .btn-radioCheckbox input[type="radio"]+label:last-child {
  border-radius: 0 0 4px 0;
}

.input-group .btn-radioCheckbox {
  width: calc(50% - 87px);
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 20px;
  margin: 0;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--white);
  border: 1px solid var(--gray);
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: var(--gray);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: var(--primary);
  border-color: var(--primary);
}

input:checked+.slider:before {
  background-color: #fff;
}

input:focus+.slider {
  box-shadow: none;
}

input:checked+.slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 40px;
}

.slider.round:before {
  border-radius: 50%;
}

.bg_source {
  background-color: var(--gray);
  font-size: 14px;
}

.bg_destination {
  background-color: var(--gray-dark);
  font-size: 14px;
}

.lh_none {
  line-height: 0px;
}

.bg_grey {
  color: black;
  background-color: #E5E7E9;
}

.accordian_items_list .accordion-header.position-relative>svg {
  padding: 4px;
  border: 1px solid currentColor;
  border-radius: 3px;
  width: 24px;
  height: 24px;
  margin-top: -2px;
  cursor: pointer;
}

.disabled-li {
  pointer-events: none;
  color: #999;
  /* Optional: You can style the disabled item differently */
}

.assignGroupWrap a:not([href]):not([tabindex]) {
  cursor: initial;
  color: var(--text-color);
  display: block;
}

.assignGroupWrap ul.dropdown-menu {
  cursor: initial;
}

.assignGroupWrap .dropright {
  padding: 0;
  position: relative;
  display: flex;
  width: max-content;
}

.panelOpen .assignGroupWrap .dropright {
  width: 100%;
  max-width: max-content;
}
.assignGroupWrap .dropright .badge.badge-warning {
	width: 100%;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
  max-width: max-content;
}
.assignGroupWrap .dropright ul.dropdown-menu {
  cursor: initial;
  padding: 0.5rem;
  left: 100% !important;
  transform: none !important;
  z-index: 2;
}
/* .fs08.p-1.badge.badge-warning.dropdown-toggle::after {
	width: auto;
	height: auto;
	content: "...";
	border: 0 none;
	vertical-align: middle;
	display: inline-block;
	margin-top: 0;
} */
.header-text {
  margin-bottom: 10px;
  font-size: 1rem;
  line-height: 12px;
}
.text-code {
  color: #a86565;
  font-family:'Courier New', Courier, monospace;
}
.text-code:last-child {
  color: var(--gray);
}

.table_items_list > .message {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
  transform: none;
  position: static;
}