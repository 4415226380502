.bg-img1,
.login {
    min-height: calc(100vh - 40px);
}
.bg-img1 + .footer {
    background-color: var(--white);
    text-align: center;
    padding: 8px;
    color: var(--label-color);
}
.heading,
.logoLogin,
.logo_login p {
    text-align: center
}

.remember,
label.withlabel-checkbox1 .fa-check:before {
    color: var(--label-color);
}

input::-ms-clear,
input::-ms-reveal {
    display: none
}

* {
    box-sizing: border-box
}

img {
    width: 100%
}

.bg-img1, .signUp {
    min-height: calc(100vh - 40px);
}

.showHidePassSignup {
    position: absolute;
    top: 38px;
    right: 10px;
}

.signup_wrapper {
    min-height: calc(100vh - 100px);
    background: rgb(249,244,236);
    background: linear-gradient(45deg, rgba(233,237,247,1) 0%, rgba(249,244,236,1) 100%);
    padding: 30px 0px 10px;
    border-radius: 20px;
    width: 520px;
    max-width: 60%;
    display: flex;
    justify-content: center;
    margin-right: 5em;
    align-items: center;
}

.signUp{
    margin: 0;
    padding: 25px 0 0 25px;
    width: 800px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bg-img1 {
    position: relative;
    display: flex;
    background: var(--white) url('../../assets/Images/Monoz_FooterLogo1.jpg') no-repeat center right / contain;
    align-items: center;
    justify-content: space-around;
}

h1 {
    font-size: 34px;
    font-weight: 700;
    margin: 0
}
.language_select {
	padding: 5px;
	line-height: 1;
	background: #fff;
	border: 1px solid var(--border-color);
	border-radius: 4px;
}
.showHidePass {
    position: absolute;
    top: 43px;
    right: 10px
}

.showHideOuterDiv {
    position: relative
}

.login_wrapper {
	min-height: calc(100vh - 100px);
	background: rgb(249,244,236);
	background: linear-gradient(45deg, rgba(233,237,247,1) 0%, rgba(249,244,236,1) 100%);
	padding: 30px 0px 10px;
	margin-left: 1rem;
	border-radius: 20px;
	width: calc(60% - 1rem);
	display: flex;
	justify-content: center;
	align-items: center;
}
.login_wrapper > div {
    height: auto !important;
    width: 100% !important;
}
.loginWrap_title {
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0 10px;
}
.login {
	margin: 0;
	width: 40%;
	max-width: 520px;
	padding: 5em;
	margin-right: 2em;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.login input.form-control {
	font-size: 13px;
	padding: 13px;
	border-color: var(--secondary);
    background-color: var(--white);
	border-radius: 6px;
}
.login input.form-control:hover,
.login input.form-control:focus,
.login input.form-control:active {
	border-color: var(--primary);
    background-color: var(--white);
	box-shadow: 0 0 3px var(--primary);
}

.logo_login p {
    color: var(--darker-gray);
    width: auto;
    font-size: 14px
}

.logo_login img {
    max-width: 90px
}

img.LightSmallLogo {
    display: none;
}

.logo_login {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	align-items: start;
}
.loginBox .checkbox {
	display: flex;
	width: 100%;
}
.logo_login p span {
    margin-bottom: 10px;
    margin-top: 10px
}

.checkbox-with-label {
    width: 100%;
    margin-bottom: 10px
}

.login button.btn.btn-primary {
	background: var(--primary-color);
	font-size: 14px;
	border-color: var(--primary-color);
	border-radius: 0.45rem !important;
	margin: 20px 0 0;
	height: auto;
	font-weight: bold;
	letter-spacing: 1px;
}

.login .btn {
    background-color: var(--secondary);
    color: var(--white);
    padding: 11px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    height: 40px
}

.custom-checkbox .checkmark {
    position: absolute;
    top: -5px;
    left: 0;
    height: 15px;
    width: 15px;
    border: 1px solid var(--label-color)
}

.checkbox .cr,
.radio .cr {
    position: relative;
    display: inline-block;
    border: 0 none;
	background: var(--light-gray);
	border-radius: 3px;
	width: 15px;
	height: 15px;
    margin-top: 5px;
    margin-right: 5px
}

.checkbox .checkbox-area .cr {
    margin-right: 0
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0
}

.checkbox label:after,
.radio label:after {
    content: '';
    display: table;
    clear: both
}

.checkbox .cr .cr-icon {
	position: absolute;
	font-size: 10px;
	line-height: 1;
	top: 2px;
	left: 2px;
	color: var(--text-color);
}

.checkbox .cr .cr-icon .fa-check::before {
	color: var(--text-color);
}
.bg-img1.background label {
	color: var(--darker-gray);
	font-size: 14px;
	margin-bottom: 6px;
	font-weight: 600;
}

.bg-img1.background {
    color: var(--darker-gray)
}

.loginBox {
    margin-top: 4px
}

.login .heading {
	font-size: 25px;
	margin: 20px 0 0;
	font-weight: 300;
}

.meritech_logo {
    width: 100%;
    max-width: 260px
}

.logoLogin {
    width: 100%;
    margin: 0 auto
}
.login .form-group {
	margin-bottom: 0.9rem;
}
.remember {
    margin-left: 4px;
    width: auto;
    margin-top: 9px;
}

label.withlabel-checkbox1 {
    width: auto;
    margin-top: 5px
}

.forgetPas{
    cursor: pointer;
    color: black;
    margin-top: 10px;
}

.ml-25{
    margin-left: 25px;
}

@media (orientation:landscape) {
    .background::before {
        background-color: var(--darker-gray);
        background-size: auto 100%
    }
}

@media (max-width:1240px) {
    .loginBox {
        margin-top: 12px
    }
}

@media (max-width:992px) {
    .bg-img1 {
        flex-wrap: wrap;
    }
    .login_wrapper {
        min-height: 200px;
        margin-left: 0;
        border-radius: 0;
        width: 100%;
    }
    .login {
        position: initial;
        width: 100%;
        padding: 20px;
        min-height: 130px;
        margin: 0;
    }

    .logo_login {
        position: inherit;
        display: flex;
        flex-direction: column;
        margin-top: 11px;
        align-items: end
    }

    .background::before {
        z-index: -1;
        background-position: center
    }
}