/* .App {
  text-align: center;
} */
#nprogress .bar { 
  height:3px !important;
} 
#nprogress .bar {
  background: #06FFF0 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #06FFF0, 0 0 5px #06FFF0 !important;
}

#nprogress .spinner-icon {
  border-top-color: #06FFF0 !important;
  border-left-color: #06FFF0 !important;
}