.sidebar-minified:not(.left-sidebar) .left-sidebar .profile-img .mdi {
    left: 3px;
}
#left-sidebarNav li img {
    max-width: 21px;
}
#left-sidebarNav li:not(.active) img,
#left-sidebarNav a:not(.active) img {
    filter: brightness(0) invert(1);
}
#left-sidebarNav li a.active img {
    filter: none;
}
.sidebar .sidebar-inner>li>a {
    padding: 9px 12px;
    font-size: 13px;
}
.sidebar .sidenav-item-link .nav-text {
    max-width: 100%;
}
.sidebar .nav>li>a i {
    font-size: 18px;
}
.nav-inner svg {
    fill: var(--text-color);
}
.nav-inner svg:hover {
    fill: var(--primary-color);
}
@media (min-width: 768px) {
    .left-sidebar {
        width: 216px;
   }
    .sidebar-fixed-offcanvas .main-header, .sidebar-fixed .main-header {
        padding-left: 13.6rem;
   }
    .sidebar-minified .profile-img img {
        width: 20px;
   }
    .sidebar-fixed-offcanvas .page-wrapper, .sidebar-fixed .page-wrapper {
        padding-left: 13.6rem;
   }
    .sidebar-minified .left-sidebar {
        width: 45px;
   }
    .sidebar-fixed.sidebar-minified.header-fixed .page-wrapper .main-header {
        padding-left: 2.85rem;
   }
    .sidebar-fixed.sidebar-minified .page-wrapper {
        padding-left: 3.00rem;
   }
    .sidebar-minified .profile-element .profile-img .mdi {
        left: 13px;
        bottom: 9px;
        font-size: 14px;
   }
    #addMessager .modal-dialog, #delete-express-application .modal-dialog {
        width: 400px;
   }
}
/* --============ New CSS =============-- */
#left-sidebarNav {
    z-index: 1000;
    position: fixed;
    width: 200px;
    height: 100%;
    left: 0px;
    top: 0px;
    background: var(--secondary);
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
    text-align: left;
    border-right: 1px solid var(--card-border);
    display: flex;
    flex-direction: column;
    
}
button#nav-toogle svg {
    overflow: hidden;
    vertical-align: middle;
}
.closeNav button#nav-toogle svg {
    margin: 7px;
}
#left-sidebarNav nav ul li a.active {
    background: var(--light-secondary);
}
#left-sidebarNav .nav-inner {
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 224px !important;
}
#left-sidebarNav.closeNav .nav-inner {
    margin-bottom: 213px !important;
}
#left-sidebarNav nav {
    position: relative;
    width: 100%;
    margin-top: 1px;
}
#left-sidebarNav .nav-inner svg {
    fill: var(--white);
}
#left-sidebarNav .nav-inner .active svg {
    fill: var(--primary-color);
}
#left-sidebarNav nav .sidebar-header .sidebar-logo {
    padding: 10px 0px;
    text-align: center;
}
#left-sidebarNav nav .sidebar-header .sidebar-logo img {
    max-width: 200px;
}
#left-sidebarNav nav ul li a i {
    padding-right: 10px;
    font-size: 20px;
    min-width: 35px;
}
#left-sidebarNav nav ul li {
    width: 100%;
}
#left-sidebarNav nav ul li a {
    padding: 7px 0 7px 10px;
    display: block;
    color: var(--text-color);
    transition: 0.4s;
    margin-bottom: 2px;
    margin-top: 3px;
    width: 100%;
}
nav ul ul a {
    font-size: 13px !important;
    padding-left: 25px !important;
    border-top: 0px;
}
#left-sidebarNav nav ul ul a i {
    font-size: 17px !important;
    padding-right: 10px !important;
    min-width: 31px !important;
    text-align: center;
}
#left-sidebarNav nav a.dropdown-toggle.collapsed::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 5px;
    vertical-align: 5px;
    content: '';
    border-bottom: 5px solid;
    border-right: 5px solid transparent;
    border-top: 0;
    margin-top: 13px;
}
#left-sidebarNav nav a.dropdown-toggle::after {
    float: right;
    margin-top: 13px;
}
.aside-footer {
    position: absolute;
    bottom: 0;
    left: 0px;
    padding: 10px 0px 0px;
    width: 100%;
    border-top: 1px solid var(--black);
}
.aside-footer ul {
    margin-bottom: 1px;
    width: 100%;
    padding: 0 0 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.aside-footer li.dropdown {
    cursor: pointer;
}
.aside-footer li {
    margin: 10px 0px;
    color: var(--black);
    width: 100%;
    text-align: left;
    cursor: pointer;
}
.closeNav .aside-footer li {
    margin: 10px 0px;
}
.closeNav .aside-footer ul {
    text-align: inherit;
}
.aside-footer li a {
    color: var(--black);
    font-size: 15px;
}
/* .aside-footer li a span {
    padding-left: 6px;
}
*/
.aside-footer li i {
    font-size: 18px;
    color: var(--text-color);
    cursor: pointer;
}
.topbar {
    background-color: var(--text-color);
    height: 30px;
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
}
.aside-footer svg {
    fill: var(--white-color);
}
.aside-footer svg g {
    stroke: var(--white-color);
}
button#nav-toogle {
	/* background-color: #ffffff; */
	width: 28px;
	height: 28px;
	font-size: 20px;
	color: var(--text-color);
	position: absolute;
	left: calc(100% + 12px);
	top: 13px;
}

.menu-toggle span {
	background-color: currentColor;
	height: 2px;
	transition-duration: .15s;
	transition-duration: .25s;
	transition-property: all;
	transition-timing-function: cubic-bezier(.4,0,.2,1);
	width: 1.25rem;
    display: block;
    margin: 5px 0;
}
.menu-toggle span:nth-child(2) {
	width: .75rem;
}
span.nav-text {
    margin-left: 3px;
}
aside:not(.closeNav) .menu-toggle span:nth-child(2) {
	display: none;
}
aside:not(.closeNav) .menu-toggle span:first-child {
	transform: rotate(-35deg);
    width: 14px;
}
aside:not(.closeNav) .menu-toggle span:nth-child(3) {
	transform: rotate(35deg);
    width: 14px;
}
.closeNav button#nav-toogle i {
    transform: rotate(180deg);
}
#left-sidebarNav nav .sidebar-header .sidebar-logo img.openNav-logo {
    max-width: 165px;
    display: block;
   /* margin: 0 auto;
    */
    padding: 0px 15px;
}
button#nav-toogle i {
    float: right;
    margin-right: 20px;
}
.closeNav button#nav-toogle i {
    float: initial;
    margin-right: 0px;
}
.aside-footer .version {
    font-size: 13px;
    margin: 0;
    color: var(--text-color);
    background-color: var(--light-gray);
    text-align: center;
    padding: 5px 10px;
    width: 100%;
}
.closeNav .aside-footer .version {
    display: none;
}
span.nav-text {
    color: var(--white-color);
    line-height: 19px;
    font-size: 13px;
    margin-top: 2px;
    padding-left: 10px;
    cursor: pointer;
}
span.menuTitle {
    font-size: 17px;
}
.closeNav span.menuTitle {
    display: none;
}
.closeNav .aside-footer ul {
    padding: 0px 0 0 15px;
}
img.AlertViewer {
    width: 22px;
}
img.vms {
    max-width: 55px;
    padding-top: 3px;
}
button#nav-toogle svg {
    margin-top: 9px;
}
.icon-nav button {
    outline: none;
}
li.search {
    position: relative;
}
.searchEnable {
    position: absolute;
    right: 9px;
    top: 3px;
}

.heading_card{
    font-weight: 600;
    font-size: 26px;
}
.xsmall{
  font-size: 0.61rem;
}
.toggle-slot {
  cursor: pointer;
  position: relative;
  margin-top: 4px;
}
.toggle-checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.sun-icon {
  height: 27px;
  width: 27px;
  color: var(--primary-color);
}
.sun-icon-wrapper {
  height: 27px;
  width: 27px;
  opacity: 1;
  transform: translate(0em, 0em);
  transform-origin: 50% 50%;
  transition: opacity 150ms, transform 500ms cubic-bezier(.26,2,.46,.71);
}
.toggle-checkbox:checked ~ .toggle-slot .sun-icon-wrapper {
  opacity: 0;
}
.moon-icon {
  height: 25px;
  width: 25px;
  color: var(--black);
}
.moon-icon-wrapper {
  position: absolute;
  top: 0;
  height: 25px;
  width: 25px;
  opacity: 0;
  transform: translate(0em, 0em) rotate(0deg);
  transform-origin: 50% 50%;
  transition: opacity 150ms, transform 500ms cubic-bezier(.26,2.5,.46,.71);
}
.toggle-checkbox:checked ~ .toggle-slot .moon-icon-wrapper {
  opacity: 1;
}
.trial.badge.badge-warning {
	position: absolute;
	left: 28px;
	bottom: 4px;
	padding: 2px;
	font-size: 9px;
}
.jpLang.trial.badge.badge-warning {
    left: 2px;
    bottom: -6px;
}


/*==== DROPDOWN MENU ==== */
#left-sidebarNav .nav-inner .list-unstyled>li .dropdown-menu {
    transform: none !important;
    display: block;
    padding: 0;
    transition: all 0.4s ease;
    height: 0;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    background-color: var(--secondary);
}

#left-sidebarNav:not(.closeNav) .nav-inner .list-unstyled>li .dropdown-menu {
    border: 0 none;
    position: static !important;
}

#left-sidebarNav.closeNav .nav-inner .list-unstyled>li .dropdown-menu {
    position: absolute;
    left: 100%;
    top: -10px;
    border-color: var(--border-color);
    box-shadow: 1px 1px 10px var(--shadow-color);
}

#left-sidebarNav:not(.closeNav) .nav-inner .list-unstyled>li .dropdown-menu.show {
    height: auto;
    opacity: 1;
    visibility: visible;
}

#left-sidebarNav .nav-inner .list-unstyled>li .dropdown-toggle::after {
    margin-left: auto;
}

#left-sidebarNav .nav-inner .list-unstyled>li .dropdown-item {
    color: var(--gray);
    border-radius: 4px;
    padding: 8px;
    margin: 0;
}

#left-sidebarNav .nav-inner .list-unstyled>li .dropdown-item:hover {
    background-color: var(--shadow-color);
}

#left-sidebarNav .nav-inner .list-unstyled>li .dropdown-item.active {
    background-color: transparent;
    color: var(--primary-color);
}

@media (min-width:768px) {
    .closeNav {
        width: 55px !important;
        overflow: initial !important;
   }
    .closeNav .collapse.show {
        display: block;
        width: 240px !important;
        position: absolute;
        left: 54px;
        background-color: var(--text-color);
        box-shadow: 2px 1px 10px -2px var(--shadow-color);
   }
    .closeNav span.nav-text {
        display: none;
   }
    .closeNav nav a.dropdown-toggle::after {
        display: none !important;
   }
    .closeNav nav a.dropdown-toggle.collapsed::after {
        display: none !important;
   }
    .closeNav .aside-footer li a span {
        display: none;
   }
    .closeNav nav ul li a {
        min-width: 55px;
   }
    .closeNav .aside-footer {
        bottom: 15px;
        text-align: center;
   }
    .closeNav .aside-footer li i {
        padding-right: 0px;
        cursor: pointer;
   }
    .closeNav i.fa.fa-power-off.logout {
        float: none;
   }
    #left-sidebarNav nav .sidebar-header .sidebar-logo img.closeNav-logo {
        max-width: 40px;
        display: none;
   }
    .closeNav nav .sidebar-header .sidebar-logo img.openNav-logo {
        display: none !important;
   }
    .closeNav nav .sidebar-header .sidebar-logo img.closeNav-logo {
        display: block !important;
        margin: 0 auto;
   }
    .closeNav nav .sidebar-header .sidebar-logo {
        padding: 12px 0px 8px !important;
   }
    .closeNav .collapsing {
        transition: 0s !important;
   }
    .dropdown-content {
        display: none;
        position: absolute;
        top: -88px;
        left: 0px;
        background-color: #f9f9f9;
        min-width: 100%;
        box-shadow: 0px 3px 6px 0px var(--shadow-color);
        z-index: 1;
   }
}
@media (max-width: 767px) {
    .wrapper .container-fluid {
        padding: 0px 15px 0px 15px;
   }
    #left-sidebarNav nav .sidebar-header .sidebar-logo img.closeNav-logo {
        display: none;
   }
    .closeNav {
        left: -250px !important;
   }
    .closeNav button#nav-toogle {
        position: fixed;
        left: 5px;
        top: 0px;
        width: 40px;
        box-shadow: none;
   }
    button#nav-toogle {
        position: fixed;
        left: 0px;
        top: 0;
        width: 239px;
   }
    #left-sidebarNav {
        top: 0px;
        padding-top: 28px;
        padding-bottom: 70px;
   }
    .dropdown-content {
        top: -90px;
   }
    .closeNav span.menuTitle {
        display: block;
   }
    .closeNav span.menuTitle img {
        display: none;
   }
    .closeNav button#nav-toogle svg {
        fill: var(--black);
        margin: 3px 0 0;
   }
    button#nav-toogle svg {
        margin-right: 45px;
   }
}
@media (max-width: 530px) {
    .row-eq-height {
        height: calc(100vh - 157px);
   }
    .historyTopnav h1 {
        margin-top: 4px;
   }
    .filter-left-area {
        margin: 0 0 10px;
   }
    .filter-left-area ul {
        width: 100%;
   }
    li.groupselectNav {
        width: 100%;
   }
    .filter-left-area li .groupSelect {
        width: 100%;
   }
    .filter-left-area {
        padding-left: 31px;
   }
    .add-list {
        padding-left: 31px;
   }
    .add-list {
        margin: 0;
   }
    .filter-left-area h1 {
        margin: 10px 0px 0px 0px;
   }
    .userTopNav .filter-left-area h1 {
        margin: 0px 7px;
   }
}
/* --============ End New CSS =============-- */