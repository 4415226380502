:root {
    --transparent-black: rgba(191, 207, 255, 0.1);
    --transparent-white: hsla(0, 0%, 100%, 0.1);
    --font-size: 16px;
    --bs-border-color: var(--card-border);
    --bs-border-radius: 0.3rem; 
    --white-color: #fff;
    --primary-color: #ff6c37;
    --primary: #f39200;
    --light-primary: #F1BD90;
    --lightest-primary: #ffe7d6;
    --red: #dc3545;
    --green: #0cbb52;
    --blue: #097bed;
    --yellow: #fec02d;
    --bs-accordion-inner-border-radius: 7px;
}
.accordion-item {
	color: var(--text-color) !important;
}
@font-face {
    font-family: "Base 4";
    src: url("../fonts/Base 4.eot");
    src: url("../fonts/Base 4.eot?#iefix") format("embedded-opentype"), url("../fonts/Base 4.woff") format("woff"), url("../fonts/Base 4.svg#Base 4") format("svg");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-007A;
}
html {
    height: 100%;
    width: 100%;
    font-size: var(--font-size);
}
body {
   /* font-family: sans-serif !important;
    */
    font-family: Nunito, sans-serif;
    background-color: var(--body-background);
    font-size: 15px;
    color: var(--text-color);
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}
* {
    outline: 0px;
}
input[type="radio"]:focus, input[type="checkbox"]:focus {
    outline: 0px;
    outline-offset: 0;
    border: 0px !important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
input[type=number]{
    -moz-appearance: textfield;
}
p {
    margin: 0px;
}
h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    color: var(--label-color);
}
h1 {
    font-size: 30px;
}
h2 {
    font-size: 24px;
}
h3 {
    font-size: 16px;
}
h4 {
    font-size: 14px;
}
h5 {
    font-size: 12px;
}
h6 {
    font-size: 10px;
}
h3, h4, h5 {
    margin-top: 5px;
    font-weight: 600;
}
ul li {
    list-style: none;
}
a {
    color: var(--primary);
    text-decoration: none;
    outline: none;
    cursor: pointer;
}
a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    outline: none;
}
::selection {
    background: var(--primary);
    color: var(--white);
}
::-moz-selection {
    background: var(--primary);
    color: var(--white);
}
option:disabled {
    color: var(--gray);
    background-color: var(--lightest-gray);
}
p {
    color: var(--text-color);
}
.text-primary {
	color: var(--primary-color) !important;
}
.text-dark {
	color: var(--dark-text) !important;
}
a.text-dark:focus, a.text-dark:hover {
	color: var(--black) !important;
}
.text-muted {
	color: var(--gray) !important;
}
.card {
	border: 1px solid var(--card-border);
	border-radius: var(--bs-accordion-inner-border-radius);
    box-shadow: 1px 1px 2px var(--shadow-color);
    background-color: var(--white);
    color: var(--text-color);
    overflow: hidden;
    height: auto;
}
.form-group {
    margin-bottom: 10px;
    position: relative;
}
.form-group.row .col-sm-12 {
    position: relative;
}
.form-control {
	min-height: 38px;
	border-radius: 4px;
	padding: 5px 10px;
	background: var(--white);
	border: 1px solid var(--input-border);
	font-size: 14px;
    color: var(--text-color);
}
.form-control[data-testid="test_password"] {
    padding-right: 40px;
}
.form-control::file-selector-button {
    padding: .485rem .75rem;
}
select.form-control:not([size]):not([multiple]) {
	height: 38px;
    padding-right: 30px;
    background: var(--white) url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAB2AAAAdgB+lymcgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAHPSURBVHic7dgtSANhGMDx/4YiU5TJwGAQLAaLoBaLFoOYbDabzWjWbrTJgmsmm0EsrlgsFsOCRVAQBEV0m98abgc6N+69u/fj3D0/eMruBu//2MZ4QAghhBBCCCGESJ1MwPVBK6cw7z7Mzb1AEagCXx0y1UZTTuUBFBNwYFOz0xzb/BXIAo94n4JO9AT0/3wh23SD/6RSo9UDKDk4hy27KjflgDLuv6+6p4zijyDAAHCagEPrmjMgrxrvyzfe6PrwceccKISN9w0BlQRERJ0LYDhqvG8EuExATNi5AkbjxvvGgJsERKnOLTCuK943AdwlIC5oHoBp3fG+Gbx/U64j200NmDUV75sHnh2HtppXYNFg9y9LwJvhoDDzDiwbLW5hBfiIeXAd8wmsGm5ta03hgKZn3XhlgE3cxW9Y6FOyhf34bStlijJ42xZb8SWC95nWZYE9zMfvA12WmkLrBg4wF38E9FiriSgHHKM//gTos9gRi+6FSqSFhmsFvGVE3PgK3l7iX4q7UNGy0HAt6kLlGo0LDdfCLlSMLDRcU12oGF1ouBa0UKkBc85OZ8kCUOdvfL1xLRWm8P7VveBtlw6BSacnEkIIIYQQQgghRGp8A7sjgU5M3b7vAAAAAElFTkSuQmCC") no-repeat right .75rem center/auto 12px;
}
select.form-control:not([size]):not([multiple]):disabled {
    background: var(--light-gray) url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAB2AAAAdgB+lymcgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAHPSURBVHic7dgtSANhGMDx/4YiU5TJwGAQLAaLoBaLFoOYbDabzWjWbrTJgmsmm0EsrlgsFsOCRVAQBEV0m98abgc6N+69u/fj3D0/eMruBu//2MZ4QAghhBBCCCGESJ1MwPVBK6cw7z7Mzb1AEagCXx0y1UZTTuUBFBNwYFOz0xzb/BXIAo94n4JO9AT0/3wh23SD/6RSo9UDKDk4hy27KjflgDLuv6+6p4zijyDAAHCagEPrmjMgrxrvyzfe6PrwceccKISN9w0BlQRERJ0LYDhqvG8EuExATNi5AkbjxvvGgJsERKnOLTCuK943AdwlIC5oHoBp3fG+Gbx/U64j200NmDUV75sHnh2HtppXYNFg9y9LwJvhoDDzDiwbLW5hBfiIeXAd8wmsGm5ta03hgKZn3XhlgE3cxW9Y6FOyhf34bStlijJ42xZb8SWC95nWZYE9zMfvA12WmkLrBg4wF38E9FiriSgHHKM//gTos9gRi+6FSqSFhmsFvGVE3PgK3l7iX4q7UNGy0HAt6kLlGo0LDdfCLlSMLDRcU12oGF1ouBa0UKkBc85OZ8kCUOdvfL1xLRWm8P7VveBtlw6BSacnEkIIIYQQQgghRGp8A7sjgU5M3b7vAAAAAElFTkSuQmCC") no-repeat right .75rem center/auto 12px;
}
.form-control::placeholder {
    font-size: 1em;
    line-height: 1;
}
.table td.pre_formatted_data {
    white-space: pre;
}
.rti--input,
.form-control:focus, #focusedInput {
    box-shadow: none;
    color: var(--text-color);
    background-color: var(--white);
}
.MuiInputBase-input {
    padding: 7px 14px !important;
}
.MuiOutlinedInput-notchedOutline,
.datepicker-control .MuiInputBase-root {
    border-color: var(--input-border) !important;
    border-width: 1px !important;
    color: var(--text-color);
}
.datepicker-control .MuiButtonBase-root {
    color: var(--text-color);
}

.form-control:disabled, .form-control[readonly] {
	background-color: var(--light-gray);
	opacity: 1;
    cursor: not-allowed;
}
.error.form-control {
    border-color: var(--red);
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--text-color);
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px var(--white);
}
input:-webkit-autofill {
    caret-color: var(--text-color);
}
.rti--container {
	--rti-bg: var(--white) !important;
	--rti-border: var(--border-color) !important;
	--rti-main: var(--primary) !important;
	--rti-tag: var(--secondary) !important;
    --rti-tag-remove: var(--white-color) !important;
}
.rti--tag span,
.rti--tag button {
    color: var(--white-color);
}
.custom-file .custom-file-label::after {
	color: var(--gray);
	content: "" !important;
	background: var(--lightest-gray) url(../Images/category_icon_vector_data-analytics.svg) no-repeat center / 20px auto;
	border-left: 1px solid var(--light-gray);
	border-radius: 0 .25rem .25rem 0;
    min-width: 30px;
}
.custom-file .custom-file-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.border-bottom {
	border-bottom: 1px solid var(--border-color) !important;
}
.input-group-text {
	background-color: var(--card-border);
	border: 1px solid var(--input-border);
}
.input-group + .input-group:not(:last-child) > .input-group-prepend > .label-style.input-group-text,
.input-group + .input-group:not(:last-child) > .custom-select:not(:first-child), 
.input-group + .input-group:not(:last-child) > .form-control:not(:first-child) {
    margin-top: calc(var(--bs-border-width) * -1);
    margin-bottom: calc(var(--bs-border-width) * -1);
}
.input-group:not(:last-child) > .input-group-prepend > .label-style.input-group-text {
    border-bottom-left-radius: 0;
}
.input-group:last-child > .input-group-prepend > .label-style.input-group-text {
    border-bottom-left-radius: 4px;
}
.input-group:first-child > .input-group-prepend > .label-style.input-group-text {
    border-top-left-radius: 4px;
}
.input-group:first-child > .input-group-prepend + .custom-select:not(:first-child), 
.input-group:first-child > .input-group-prepend + .form-control:not(:first-child) {
    border-top-right-radius: 4px;
}
.input-group:last-child > .input-group-prepend + .custom-select:not(:first-child), 
.input-group:last-child > .input-group-prepend + .form-control:not(:first-child) {
    border-bottom-left-radius: 4px;
}
.input-group-text {
    color: var(--light-label);
}
label {
	font-weight: normal;
	color: var(--label-color);
	margin-bottom: .1em;
}
.p-0 {
    padding: 0px;
}
::-ms-clear {
    display: none;
}
input[disabled] {
    cursor: default;
    background: var(--input-border);
    color: var(--label-color);
    border-color: var(--input-border);
}
ul {
    margin: 0px;
    padding: 0px;
}
a[disabled] {
    pointer-events: none;
    color: #95979a;
}
.form-control:focus, #focusedInput {
    border: 1px solid #cf7c00 !important;
    box-shadow: none;
}
/*textarea*/
textarea {
    overflow: auto;
    width: 100%;
    border: 1px solid var(--input-border);
    padding: 5px;
   /* resize: none;
    */
}
.form-group.textarea {
    width: 100%;
    margin: 10px 0;
}
.textarea {
    width: 100%;
}
/*select*/
select option:hover {
    box-shadow: 0 0 10px 100px var(--primary) inset;
    background: var(--primary);
    color: var(--white);
}
option[selected]:hover {
    background: var(--primary);
    color: var(--white);
}
select[disabled] {
    cursor: default;
    background: var(--input-border);
    color: var(--label-color);
    border-color: var(--input-border);
    border: 1px solid var(--input-border);
}
/*button*/
.btn:not(.btn-sm) {
	text-align: center;
	padding: 7px 13px;
	font-size: 15px;
	line-height: 1;
}
.btn-sm {
    font-size: 0.75rem;
}
.btn-outline-primary:hover,.btn-outline-primary:focus,
.btn.btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--white);
    box-shadow: none;
}
.btn-primary.disabled, .btn-primary:disabled {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}
.btn-primary:hover:not(.disabled):not(:disabled) {
    box-shadow: 0 8px 25px -8px var(--shadow-color);
}
button.btn.btn-primary:focus {
    background: var(--primary);
    color: var(--white-color);
    border-color: var(--primary);
    box-shadow: none;
}
.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle,
.btn-primary:not(:disabled):not(.disabled).active, 
.btn-primary:not(:disabled):not(.disabled):active, 
.show > .btn-primary.dropdown-toggle {
	color: var(--white-color);
	background-color: var(--primary-color);
	border-color: var(--primary-color);
    box-shadow: none;
}
.btn-outline-primary {
	color: var(--primary-color);
    border-color: var(--primary-color);
}
.btn-outline-primary.disabled, 
.btn-outline-primary:disabled {
	color: var(--gray);
	background-color: transparent;
	border-color: var(--gray);
	cursor: not-allowed;
}
.btn-secondary {
    color: var(--white);
    background-color: var(--secondary);
    border-color: var(--secondary);
}
.btn-light {
	color: var(--text-color) !important;
	background-color: var(--white);
	border: 1px solid var(--white);
}
.btn-light:focus,
.btn-light:active,
.btn-light:hover {
	color: var(--black) !important;
	background-color: var(--lightest-gray) !important;
	border: 1px solid var(--light-gray) !important;
}
.btn-danger {
    color: var(--white);
    background-color: var(--red);
    border-color: var(--red);
}
.btn-danger.btn-primary:hover:not(.disabled):not(:disabled) {
    box-shadow: 0 8px 25px -8px var(--red);
}
button.btn-danger.btn.btn-primary:focus {
    background: var(--red);
    color: var(--white);
    border-color: var(--red);
    box-shadow: none;
}
.btn-info {
    color: #fff;
    background-color: #4b93ef;
    border-color: #4b93ef;
}
.btn-link {
    color: var(--black);
}
.btn-link:hover,
.btn-check:checked+.btn, 
.btn.active, 
.btn:first-child:active, 
:not(.btn-check)+.btn:active {
    color: var(--primary);
    border-color: transparent;
}
.tab-action button+button {
    margin-left: 13px !important;
    margin-top: 0px !important;
}
button.close:focus {
    color: var(--text-color);
    outline: none;
}
button:focus {
    outline: 0px !important;
}
span.closebtn {
    float: right;
    font-size: 20px;
    cursor: pointer;
}
.btn-primary:hover:not(.disabled):not(:disabled) {
    box-shadow: 0 8px 25px -8px var(--primary);
}
button {
    background: transparent;
    border: none;
    cursor: pointer;
}
.filter-left-area li:first-child a {
    padding-left: 0px;
}
a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: var(--primary);
}
.pagination>li>a {
    cursor: pointer !important;
    color: var(--black);
}
.pagination>.active>a:not([href]):not([tabindex]):focus, .pagination>.active>a:not([href]):not([tabindex]):hover {
    color: var(--white);
}
.btn-sm + .dropdown-menu .dropdown-item {
    font-size: 0.75rem;
}
.dropdown-item {
    cursor: pointer;
    color: inherit;
}
.dropdown-header {
    color: inherit;
}
.badge-light {
	color: var(--gray);
	background-color: var(--light-gray);
}
.badge.bg-warning {
    color: #000;
}
/*search*/
.page-input-search::-webkit-outer-spin-button, 
.page-input-search::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.page-input-search[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
}
.searchPlaceholder::-webkit-input-placeholder {
    font-size: 9px;
}
/*custome scrollbar*/
::-webkit-scrollbar {
    -webkit-appearance: none;
}
::-webkit-scrollbar:vertical {
    width: 7px;
}
::-webkit-scrollbar:horizontal {
    height: 7px;
}
::-webkit-scrollbar-thumb {
    background-color: var(--gray);
    border-radius: 4px;
}
::-webkit-scrollbar-track {
    border-radius: 4px;
}
::-webkit-input-placeholder {
    color: var(--black);
}
/*placeholder*/
.form-control::-moz-placeholder {
    color: var(--text-color);
}
.form-control:-ms-input-placeholder {
    color: var(--text-color);
}
.form-control:-moz-placeholder {
    color: var(--text-color);
}
/*table*/
.table>thead>tr>td.active, .table>tbody>tr>td.active, .table>tfoot>tr>td.active, .table>thead>tr>th.active, .table>tbody>tr>th.active, .table>tfoot>tr>th.active, .table>thead>tr.active>td, .table>tbody>tr.active>td, .table>tfoot>tr.active>td, .table>thead>tr.active>th, .table>tbody>tr.active>th, .table>tfoot>tr.active>th {
    background-color: #ebf2f9;
}
.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
    vertical-align: middle;
}
.table thead th {
    border-bottom: 1px solid var(--card-border);
}
.tableDesign {
    position: relative;
}
.tableDesign thead {
    background: var(--secondary);
    position: sticky;
    top: -1px;
    z-index: 4;
}
.table-bordered > :not(caption) > * {
	border-color: var(--border-color) ;
}
.tableDesign tbody tr td:first-child {
    border-left: 0px;
}
.tableDesign tbody tr td:last-child {
    border-right: 0px;
}
.tableDesign th {
	color: var(--white-color) !important;
	background: var(--secondary);
}
.tableDesign table.table.table-bordered {
    border-bottom: 1px solid var(--input-border);
    border-left: 0px;
    border-right: 0px;
    position: relative;
    border-top: 0px;
}
.tableDesign .table thead th {
    border: 0px;
    font-weight: bold;
}
.row-eq-height {
    height: calc(100vh - 62px);
    background: var(--white);
    box-shadow: 0 4px 24px 0 var(--shadow-color);
    border: 1px solid var(--card-border);
    overflow: auto;
}
.row-eq-height .table {
    height: auto !important;
}
.table {
    background-color: var(--input-border);
    height: 100%;
    margin-bottom: 0;
}
.table-responsive {
    height: 100%;
}
tr.YellowAlert {
    background: var(--primary);
}
.table td, .table th {
    border: 1px solid var(--border-color);
    text-align: left;
    padding: 7px;
    white-space: nowrap;
    color: var(--text-color);
    font-size: 14px;
    line-height: 11px;
}
tbody {
    display: table-row-group;
    vertical-align: middle;
}
/*backgrounds*/
.bg-primary {
	background-color: var(--primary-color) !important;
}
.bg-lightgray {
	background-color: var(--lightest-gray) !important;
}
.bg-gray {
	background-color: var(--light-gray) !important;
}
.accordion-item {
	color: var(--text-color) !important;
	background-color: var(--white) !important;
	border: 1px solid var(--border-color) !important;
}
.dropdown-menu {
    background-color: var(--white);
    color: var(--text-color);
}

[data-bs-theme="dark"] .dropdown-menu {
    background-color: var(--light-gray);
}
.dropdown-item:focus, 
.dropdown-item:hover {
	color: var(--text-color);
	background-color: var(--light-gray);
}
/*wrapper body content*/
.wrapper {
    display: block;
    margin-top: 53px;
    width: 100%;
    padding-left: 27px;
}
/* .wrapper .container-fluid {
    padding: 0px 15px 0px 70px;
}
*/
.wrapper .ShiftLeftSide {
    padding: 0px 0 0px 139px;
}
[data-bs-theme="dark"] .react-tooltip,
[data-bs-theme="dark"] .react-tooltip * {
    background-color: var(--gray);
    color: var(--black);
}
[data-bs-theme="dark"] .Crossicons svg {
    fill: var(--black);
}

.react-tooltip.dark-theme-wrap{
    background-color: var(--gray);
    color: var(--black);
}
.wrapper .ShiftLeftSide .icon-nav {
    padding-left: 194px;
}
.content-wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
}
/*nav bar top of page */
nav {
    position: relative;
    width: auto;
}
.navbar-fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    top: 0px;
}
.navbar {
    min-height: 30px;
}
.add-list {
    margin: 0;
    display: flex;
    flex-direction: row-reverse;
}
.add-list li {
    cursor: pointer;
}
.add-list li.disabled {
    cursor: not-allowed;
}
.add-list li a {
    cursor: pointer;
    text-align: left;
    font-size: 14px;
}
.add-list .fa, .add-list .fas {
    color: var(--white);
}
.styled-list {
	padding-left: 20px;
}
.styled-list li {
	list-style: disc;
}
.filter-left-area h1 {
    margin: 5px 0px 0px 0px;
    font-size: 14px;
    font-weight: normal;
}
.filter-left-area h1 img {
    width: 18px;
    margin-right: 4px;
}
.filter-left-area {
    width: auto;
    display: flex;
    min-height: 32px;
}
.filter-left-area a {
    font-size: 18px;
    color: var(--black);
    cursor: pointer;
    line-height: 24px;
    padding: 0 6px;
}
.add-list li a:hover {
    color: var(--primary);
}
.icon-nav {
    width: 100%;
    border-bottom: 1px solid var(--border-color);
    background: var(--white);
    z-index: 999;
    position: fixed;
    padding: 10px 0 10px 52px;
    box-shadow: 0 4px 24px 0 var(--shadow-color);
    border-bottom: 1px solid var(--card-border);
}
.icon-nav .row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}
.filter-left-area .mob-search li a {
    color: var(--label-color);
    font-size: 21px;
    line-height: 26px;
}
.filter-left-area .mob-search li.add-mob a {
    margin-right: 0px;
}
.icon-nav.m-b-0 {
    margin-bottom: 0;
}
.page-count-no select {
    padding: 0px 0px;
    border: 1px solid var(--input-border);
    height: 25px;
    width: auto;
    position: relative;
    margin: 0px;
}
.page-count-no select option {
    position: absolute;
    top: -5px;
}
.page-count-no select:hover {
    background: var(--white);
}
.page-number input[type="text"] {
    width: 100px;
}
.page-count-no {
    margin: 0 3px;
    padding: 0px 0 0;
}
.page-number {
    line-height: 10px;
}
.page-number ul li a, .page-number ul li .page-span {
    height: 25px;
    margin: 0;
    width: auto;
    border-left: 0px;
    text-align: center;
    padding: 2px 8px 3px;
    line-height: 20px;
    border: 1px solid var(--input-border);
    border-left: 0px;
}
.page-count-no {
    padding: 0 6px;
    margin-right: 0px;
    padding-right: 0px;
}
/*custome checkbox*/
span.cr:focus {
    border: 1px solid var(--primary) !important;
}
.checkbox .cr .cr-icon, .radio .cr .cr-icon {
    position: absolute;
    font-size: 9px;
    line-height: 0;
    top: 52%;
    left: 13%;
}
.checkbox label input[type="checkbox"], .radio label input[type="radio"] {
    display: none;
}
.checkbox label input[type="checkbox"]+.cr>.cr-icon, .radio label input[type="radio"]+.cr>.cr-icon {
    opacity: 0;
}
.checkbox label input[type="checkbox"]:checked+.cr>.cr-icon, .radio label input[type="radio"]:checked+.cr>.cr-icon {
    opacity: 1;
}
.checkbox label input[type="checkbox"]:disabled+.cr, .radio label input[type="radio"]:disabled+.cr {
    opacity: 0.5;
}
/*modal pop up width correction*/
.range-modal .modal-dialog.modal-dialog-centered {
    width: 635px;
}
.radio label, .checkbox label {
    min-height: auto;
    padding-left: 0px;
    width: auto;
    margin: 0px;
    vertical-align: middle;
}
.checkbox {
    margin: 0px;
}
.form-check.form-switch.ml-4 {
	margin-top: -3px;
}
/*custome radio button*/
input[type="radio"]+label::before {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    height: 14px;
    width: 14px;
    background:  var(--white);
    border: 1px solid var(--gray);
    border-radius: 8px;
    left: 0px;
}
input[type="radio"]+label::after {
    content: "";
    display: block;
    position: absolute;
    top: 8px;
    height: 8px;
    width: 8px;
    background: var(--white);
    border-radius: 8px;
    left: 3px;
}
input[type="radio"]+label {
    position: relative;
    padding: 0 0 0 18px !important;
    line-height: 25px;
    margin: 0 6px 0 0;
}
input[type="radio"] {
    position: absolute;
    opacity: 0;
    -moz-opacity: 0;
    -webkit-opacity: 0;
    -o-opacity: 0;
    width: auto;
}
input[type="radio"]:checked+label::before {
    background: var(--primary);
    border-color: var(--primary);
}
input[type="radio"]:disabled+label {
    opacity: 0.5;
}
.radio {
    margin-bottom: 0px;
}
/*modal pop up width Time Correction*/
.modal-dialog {
    max-width: 770px;
}
.modal-dialog.modal-sm {
    max-width: 400px;
}
.modal-dialog.modal-xl {
    max-width: 900px;
}
.modal {
    height: 100%;
}
.modal-body {
    padding: 15px 20px 15px;
    background: var(--white);
    width: 100%;
}
.modal-body.noBackground {
    background: transparent;
}
.modal-header.modal-title {
    width: 100%;
}
.modal-title {
	font-size: 20px;
	text-align: center;
	font-weight: 600;
	margin: 0;
}
.modal-content {
    border: var(--light-secondary);
    background-color: var(--white);
}
.fancy-modal .modal-content {
    padding-left: 10px;
}
.fancy-modal .modal-content::after {
	content: '';
	background: url('../Images/tile-2822716_960_720.jpg') no-repeat center / cover;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	height: 100%;
	width: 10px;
	border-radius: 5px 0 0 5px;
}
.modal-header {
	padding: 15px;
	border: 0 none;
	background: var(--lightest-gray);
	color: var(--black);
}
.modal-header button.close {
	opacity: 1;
	box-shadow: none;
	font-weight: normal;
	margin: 0;
	color: var(--light-gray);
	font-size: 40px;
	position: absolute;
	right: 0;
	top: 0;
	padding: 0 10px;
	line-height: 1;
	height: auto;
    text-shadow: var(--shadow-color);
}
.modal-header .Crossicons {
	position: absolute;
	right: 6px;
	top: 9px;
}
.modal-header svg {
	fill: var(--gray);
	width: 40px;
	height: 40px;
}
.modal-footer {
	text-align: right;
	background: var(--white);
	padding: 10px 15px;
	border-top: 2px solid var(--card-border);
}
.modal-footer .form-group {
    margin-bottom: 0rem;
}
.delete-popup.modal-dialog {
    width: 400px;
}
.delete_popup .modal-header {
	text-align: center;
	justify-content: center;
	background: var(--white);
}
.modal-dialog .loaderContainer {
    width: 100%;
    transform: scale(4.5);
    transform-origin: center;
}
/*modal pop up width correction*/
.correction-modal .modal-dialog.modal-dialog-centered {
    width: 400px;
}

/*modal pop up width correction*/
.pricing-menu.correction-modal .modal-dialog.modal-dialog-centered {
    width: 600px;
}
/*modal pop up width user*/
.add-user-modal .modal-dialog {
    width: 460px !important;
}
/*modal pop up width imei lock*/
.imei-lock-modal .modal-content  {
    max-width: 400px !important;
}
/*modal pop up width device*/
.add-device-modal .modal-dialog {
    width: 750px !important;
}
/*modal pop up width change password*/
.changePasswordModal .modal-dialog {
    width: 550px !important;
}
/*modal pop up width change logout*/
.modal-dialog.no-data-found-popup {
    width: 300px;
}
/*modal pop up width Stop Alert-Confirmation*/
.modal-dialog.stop-popup {
    width: 400px;
}
/*modal pop up for delete Confirmation*/
.modal-dialog.alert_popup,
.modal-dialog.delete_popup {
	max-width: 420px;
	text-align: center;
}
.modal-dialog.delete_popup .modal-footer {
    background-color: var(--white);
    justify-content: center;
}
.modal-dialog.delete_popup .modal-title::after {
	content: '!';
	display: block;
	width: 80px;
	height: 80px;
	background: transparent;
	border-radius: 50%;
	margin: 30px auto 0;
	line-height: 80px;
	color: var(--red);
	font-size: 40px;
	border: 2px solid #f43d3d;
    transform: scale(0);
    transition: all 0.3s ease 0.2s;
}
.show .modal-dialog.delete_popup .modal-title::after {
    transform: scale(1);
}
.alert_popup_img{
    width:90px;
    margin: 0 auto;
    display: block;
    transform: scale(0);
    transition: all 0.3s ease 0.2s;
}
.show .modal-dialog .alert_popup_img{
    transform: scale(1);
}
.add-profile-modal .modal-dialog {
    width: 430px;
}
.errorMessage {
    font-size: 12px;
    width: 100%;
    text-align: left;
}
.mb-1 {
    margin-bottom: 1rem !important;
}
.customPages svg {
    fill: var(--black);
}
.bootbox-body {
    line-height: 23px;
    word-break: break-word;
}
.add-deviceData-modal .modal-footer {
    border-top: 0px solid var(--input-border);
}
/*loader css*/
@keyframes spinner-border {
    to {
        transform: rotate(360deg);
   }
}
.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
   /* vertical-align: text-bottom;
    */
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border .75s linear infinite;
    margin-left: 5px;
}
.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}
@keyframes spinner-grow {
    0% {
        transform: scale(0);
   }
    50% {
        opacity: 1;
   }
}
.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: spinner-grow .75s linear infinite;
}
.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}
.getFlex {
    display: flex;
}

.relayError.text-danger {
	position: absolute;
	right: 1px;
	width: 40px;
	height: calc(100% - 2px);
	top: 1px;
	text-align: center;
	background-color: var(--white);
}
.relayError.text-danger i {
	font-size: 20px;
	line-height: 1.7;
}
.relayError .tooltiptext {
    visibility: hidden;
    width: auto;
    min-width: 150px;
    background-color: var(--white);
    color: var(--red);
    border: 1px solid var(--red);
    text-align: center;
    border-radius: 6px;
    padding: 4px 0px;
    position: absolute;
    z-index: 1;
    font-size: 12px;
    right: 100%;
    top: 4px;
    z-index: 4;
    margin: 0px;
}
.relayError.text-danger:hover .tooltiptext {
    visibility: visible;
}
.oneNCEDefaultError {
    width: 20px;
}
.nceError{
    height: auto;
    padding: 5px;
    background-color: var(--light-gray);
}
.fs14{
    font-size: 14px;
    font-family: Nunito, sans-serif;
    letter-spacing: 0px;
    font-weight: normal;
}
.no_data{
    width: 60px;
    height: 55px;
}
.message {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}
.messageLine{
    padding-top: 5px;
}

.activate_message_wrap {
	max-width: 550px;
	border: 1px solid var(--lightest-gray);
	padding: 60px;
	border-radius: 10px;
	box-shadow: 0 0 10px var(--light-gray);
    background-color: var(--white);
}
/* responsive view start*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .device-detail .table {
        overflow-x: auto;
   }
}
.groupSelect {
    padding: 2px;
    border: 1px solid var(--input-border);
    border-radius: 5px;
   /* max-width: 80px;
    */
    text-overflow: ellipsis;
}
.timeCorrection {
    padding-left: 8px;
    padding-top: 1px;
}
.errMessageRange {
    position: absolute;
    top: 100%;
    left: 0;
    width: 50%;
    line-height: 1;
}
.errMessageRange:last-child {
    left: calc(50% + 10px);
}
img.gridChartIcons {
    height: 16px;
    width: 16px;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    -webkit-transform: translate(14px, 4px) scale(1);
    -moz-transform: translate(14px, 4px) scale(1);
    -ms-transform: translate(14px, 4px) scale(1);
    transform: translate(14px, 4px) scale(1);
}
.react-bootstrap-table table {
    table-layout: inherit !important;
}
.tableHeaderFixed thead {
    background: var(--secondary);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
.tableHeaderFixed thead tr {
    width: 100%;
    display: table;
}
.tableHeaderFixed {
    padding-top: 25px;
    position: relative;
}
.tableDesign.row-eq-height.tableHistory {
    height: auto;
}
.required {
    color: red;
    margin-left: 4px;
}

#moveContent .sliding-panel-container {
	width: 30vw;
	right: 0;
	left: auto;
    top: 52px;
    background-color: transparent;
}
#moveContent .sliding-panel-container.active {
    z-index: 200;
}
#moveContent .card {
    width: 100%;	
    text-decoration: none;
}
/* #moveContent .card.changingPanel {
    transition: all 0.4s ease;
} */
.changingPanel tr {
	cursor: pointer;
}
.changingPanel tr:hover {
	background-color: rgba(0,0,0,0.1) !important;
}
.changingPanel tr .btn:disabled {
    cursor: not-allowed;
}
#moveContent .card.mt-3.panelOpen {
	width: 64vw;
}
.card.mt-3.panelOpen th,
.card.mt-3.panelOpen td ,
.card.mt-3.panelOpen td p {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card.mt-3.panelOpen .hidePanel {
	display: none;
}
.no_message {
    width: 170px;
}
.no_data_grid {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
div[data-bs-theme="dark"] .no_message {
    filter: invert(0.8);
}
@media (min-width:1400px) {
    .row-eq-height {
        height: auto;
        overflow: auto;
        max-height: calc(100vh - 48px);
   }
}
@media (max-width:1200px) {
    .wrapper .ShiftLeftSide .icon-nav {
        padding-left: 0;
   }
    .wrapper .ShiftLeftSide {
        padding: 0;
   }
    .wrapper .ShiftLeftSide .chartFilter {
        padding-left: 0;
   }
}
@media (max-width: 930px) {
    .icon-nav {
        height: auto;
   }
    .add-list {
        margin: 10px 0 0;
        flex-direction: column;
        width: 100%;
   }
    .row-eq-height {
        margin-top: 37px;
        height: calc(100vh - 102px);
   }
}
@media (max-width:768px) {
    .wrapper .container-fluid {
        padding: 0 10px !important;
   }
    .modal-dialog {
        width: 98%;
   }
    .icon-nav {
        height: auto;
   }
    .row-eq-height {
        height: 100%;
   }
    .icon-nav {
        padding-left: 0;
   }
    .alertVTop .groupSelect {
        width: auto;
   }
    .filter-left-area {
        padding-left: 57px;
   }
    .add-list {
        padding-left: 57px;
   }
}
@media (max-width:767px) {
    .wrapper {
        padding-left: 0;
   }
    .row-eq-height {
        height: calc(100vh - 84px);
   }
}
@media (max-width:700px) {
    .add-profile-modal .modal-dialog {
        width: 93%;
   }
    .row-eq-height {
        margin-top: 43px;
   }
    .filter-left-area {
        float: right;
        margin: 0 0 10px;
   }
    .add-list {
        margin: 0 0 8px;
        float: left !important;
   }
    .filter-left-area {
        width: 100%;
   }
}
@media (max-width:500px) {
    .icon-nav {
        padding: 5px 0px 2px;
   }
    .add-user-modal .modal-dialog {
        width: 100% !important;
   }
    .changePasswordModal .modal-dialog {
        width: 100% !important;
   }
    .range-modal .modal-dialog.modal-dialog-centered {
        width: 100% !important;
   }
    .add-deviceData-modal .nav-link {
        padding: 0.5rem;
   }
}
@media (max-width:380px) {
    .tableDesign.row-eq-height.tableHistory {
        margin-top: 71px;
   }
    .relayError .tooltiptext {
        width: 100%;
   }
}
@media (max-width:300px) {
    .currentPage {
        padding-left: 0px !important;
   }
}

.react-tooltip {
	z-index: 9999;
}

.row-eq-height2 {
    height: calc(100vh - 125px);
    /* box-shadow: 0 4px 24px 0 var(--shadow-color);
    border: 1px solid var(--card-border); */
    overflow: auto;
  }