.well {
	padding: 2.1em 1.3em;
	background: var(--card-border);
	border-radius: 11px;
}
.showHidePass.tenantBox {
	top: 33px;
	right: 9px;
}
.step-container {
	position: relative;
	text-align: center;
	transform: translateY(-43%);
}

.step-circle {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: var(--white);
	border: 2px solid var(--card-border);
	line-height: 30px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
	cursor: pointer; /* Added cursor pointer */
}
.step-circle.active {
	background-color: var(--primary-color);
	color: var(--white-color);
	border: 2px solid var(--primary-color);
}
.step-circle.completed {
	background-color: var(--green);
	color: var(--white-color);
	border: 2px solid var(--green);
}

.step-line {
	position: absolute;
	top: 16px;
	left: 50px;
	width: calc(100% - 100px);
	height: 2px;
	background-color: var(--primary-color);
	z-index: -1;
}

#multi-step-form{
	overflow-x: hidden;
}
.step-progress {
	height: 3px;
	background: var(--light-gray);
}
.step-progress .progress-bar {
	background-color: var(--primary-color);
}
.true.billing_wrap {
	transition: all 0.3s ease;
	height: 0;
	overflow: hidden;
}
.false.billing_wrap {
	height: auto;
	transition: all 0.3s ease;
}