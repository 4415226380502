.navbar-expand-lg {
	justify-content: space-between !important;
}

.dropbtn {
	color: var(--primary-color);
	padding: 16px;
	font-size: 16px;
	border: none;
	cursor: pointer;
}

.dropdown {
	position: relative;
	display: inline-block;
}

.dropdown-content {
	display: none;
	position: absolute;
	background-color: var(--lightest-gray);
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px var(--shadow-color);
	z-index: 1;
}

.dropdown-content a {
	color: var(--black);
	padding: 12px 16px;
	text-decoration: none;
	display: block;
	cursor: pointer;
}

/* .dropdown-content a:hover {background-color: #f1f1f1} */

.dropdown:hover .dropdown-content {
	display: block;
}

.dropdown:hover .dropbtn {
	background-color: var(--primary-color);
	color: var(--lightest-gray);
}

header#header {
	display: none;
}