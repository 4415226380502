.heading_card{
    font-weight: 600;
    font-size: 26px;
}
.xsmall{
  font-size: 0.61rem;
}
.dashboard_heading {
  background: var(--transparent-black);
  margin: 0 -10px 5px -10px;
  padding: 20px 11px;
}
.dashboard_heading p {
	color: var(--text-color);
}
.dashboard_blocks .card {
	padding: 13px;
	position: relative;
	min-height: 65px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
}
.number {
	font-size: 6rem;
	font-weight: 900;
	opacity: 0.22;
	position: absolute;
	right: 0;
  color: var(--primary-color);
}
.dashboard_blocks .card h3 {
	padding-right: 37px;
  }
.dashboard_blocks .card p {
  font-size: 0.7rem;
  color: var(--gray);
  padding-right: 60px;
}
.card-head {
  font-size: 14px;
  font-weight: 600;
  min-height: 30px;
  justify-content: space-between;
  display: flex;
}

.fs50{
  font-size: 34px;
  font-weight: 500;
}

.no_devices_pie {
	text-align: center;
	min-height: 226px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.card-head.textWhite.min-header.py-2 {
	min-height: 43px;
}

.dashboard_blocks a.card {
	cursor: pointer;
	min-height: 103px;
}

.crossline {
	text-decoration: line-through;
}

.nosvgs {
	max-width: 90px;
}
div[data-bs-theme="dark"] .nosvgs {
	filter: invert(0.8);
}

.resetzoomsvgs {
	max-width: 24px;
}
div[data-bs-theme="dark"] .resetzoomsvgs {
	filter: invert(0.8);
}

.resettext{
    font-size: 15px;
    font-weight: 700;
	cursor: pointer;
}

.information {
	padding: 5px 15px;
	border: 1px solid var(--primary);
	border-radius: 12px;
	color: var(--primary-color);
	background: var(--lightest-primary);
}
.cardNomessage {
	min-height: 89px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.cardPaidmessage {
	min-height: 65px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.no_message_card {
	width: 40px;
}
.recent_device_card{
	max-height:102px;
	overflow-y: scroll;
}
.chartwrapper {
	max-width: 615px;
	height: 230px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.barchartwrapper {
	max-width: 100%;
	height: 328px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.doughnut_wrap {
	width: 208px;
	height: 240px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}
.blockedgroupName {
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--darker-gray);
}
@media (min-width: 1900px) {
	.barchartwrapper {
		max-width: 790px;
		height: 382px;
	}
	.chartwrapper {
		width: 100%;
		height: 286px;
	}
	.doughnut_wrap {
		width: 318px;
		height: 339px;
	}
}