input[type="radio"]+label {
    position: relative;
    padding: 0 0 0 18px !important;
    line-height: 25px;
}

.user-profile-popup .tab-content {
    margin-bottom: 0px;
}

.nav-tabs {
    border-bottom: 1px solid var(--secondary);
}
.add-profile-modal .modal-header {
	margin-bottom: 20px;
	text-align: center;
	background: var(--secondary);
	color: var(--white-color);
	position: relative;
	padding-top: 20px;
}
.add-profile-modal .modal-header::before {
	width: 100%;
	content: '';
	position: absolute;
	background: var(--secondary);
	display: block;
	height: 45px;
	border-radius: 100%;
	top: 78%;
	left: 0;
	z-index: 0;
}
.add-profile-modal .modal-header .modal-title {
	margin: 0 auto;
    z-index: 3;
}
.add-profile-modal .form-title {
    font-weight: 500;
    font-size: 1.2rem;
    margin-top: 13px;
}
.add-profile-modal input[type="radio"] + label {
    padding: 0 !important;
    margin: 0;
    text-align: center;
    color: var(--gray);
}
.add-profile-modal input[type="radio"] + label::after,
.add-profile-modal input[type="radio"] + label::before {
    display: none;
}
.add-profile-modal label svg {
	fill: var(--gray);
	width: 100%;
	padding: 26px;
    margin: 16px 0;
	border: 2px solid var(--light-gray);
	border-radius: 6px;
}
.add-profile-modal input[type="radio"]:checked + label svg {
    fill: var(--primary);
    border-color: var(--primary);
    background-color: #f3920017
}
.add-profile-modal .modal-header .Crossicons {
	position: absolute;
	right: 0;
	top: 4px;
}
.profileIcon {
    color: var(--transparent-black);
    position: absolute;
    z-index: 0;
    font-size: 200px;
    top: -44px;
    left: 20px;
}

.close:focus,
.close:hover {
    color: var(--gray);
}

a#controlled-tab-example-tab-Settings {
    display: none;
}

.add-profile-modal .nav-tabs {
	border: 1px solid var(--light-gray);
	display: flex;
	margin: 0 auto;
	justify-content: center;
	align-items: center;
	width: auto;
	border-radius: 20px;
	max-width: 100%;
    box-shadow: 0 0px 5px var(--light-gray);
}
.nav-tabs .nav-item .nav-link, 
.nav-tabs .nav-link {
	color: var(--text-color);
	background-color: var(--white);
	border-radius: 30px;
	width: 50%;
	text-align: center;
    border: 0 none;
    margin: 0;
}
.nav-tabs .nav-item.show .nav-link, 
.nav-tabs .nav-link.active {
	color: var(--white-color);
    background: var(--primary);;
    background: radial-gradient(circle, var(--primary) 23%, var(--primary-color) 100%);
	border-color: var(--primary-color);
}
.copy-code {
	cursor: pointer;
	position: absolute;
	right: 1%;
	top: 15px;
}

@media (max-width: 600px) {
    .language {
        width: 50%;
    }
}