.modal-content .heightTable {
    min-height: 150px;
    position: relative;
    height: calc(100vh - 430px);
}
.divider_heading {
	position: relative;
    border-bottom: 1px solid  var(--light-gray);
    padding-bottom: 14px;
    margin-bottom: 14px;
}

#my-tooltip2 {
    z-index: 9;
}

.col-sm-12.form-group.formSet {
    position: relative;
    z-index: 1;
}
.inlineErrors .errorMessage {
    position: absolute;
    top: 100%;

}
.inlineErrors .form-control:first-child + span.errorMessage  {
    left: 0;
}
.inlineErrors .form-control:not(:first-child) + span.errorMessage  {
    left: 331px;
}