
.remark-tooltip {
    width: 250px;
    white-space: break-spaces;
    word-break: break-word;
    font-size: 13px;
}

.table_items_list td.audit-remarks {
    display: flex;
    overflow: visible;
    text-overflow: inherit;
    max-width: 340px;
}

.audit-remarks p {
    overflow: hidden;
    text-overflow: ellipsis;
}
.codeBlock p {
    color: var(--light-text);
}
.date_code {
	width: 80px;
}
.codeBlock .detailBlock {
	color: var(--light-text);
	position: relative;
	width: calc(100% - 120px);
	word-wrap: break-word;
}
.detailBlock::before {
	content: "";
	height: 100%;
	width: 1px;
	border-left: 1px solid var(--light-gray);
	position: absolute;
	left: -30px;
	z-index: 0;
}
.codeline {
	border: 1px solid var(--light-gray);
	padding: 10px;
	border-radius: 8px;
	background-color: var(--lightest-gray);
	margin-top: 20px;
	position: relative;
}
.codeline .text-dark {
    max-height: 241px;
    overflow-y: scroll;
    display: block;
    padding: 0;
}
.codeline::before {
	width: 30px;
	height: 1px;
	background: var(--light-gray);
	content: '';
	position: absolute;
	left: -30px;
	top: 23px;
}
.codeBlock .date_code + svg {
	fill: var(--primary);
	width: 35px;
	height: 35px;
	margin: 8px 13px 10px 2px;
	position: relative;
	z-index: 3;
}
.action_on {
	position: absolute;
	left: -48px;
	width: 30px;
	height: 30px;
	background: var(--green);
	color: var(--white-color);
	border-radius: 50%;
	text-align: center;
	line-height: 30px;
	font-size: 12px;
	text-transform: uppercase;
	top: 8px;
}
.action_on svg {
	width: 20px;
}