#doc_home {
	text-align: justify;
}
.sidebar-wrapper {
	background: var(--border-color);
	transform: translateX(-14px);
	height: calc(100vh - 53px);
  padding: 30px 0;
  position: sticky;
  top: 53px;
}  
.sidebar-wrapper #faq li a {
  color: var(--text-color);
  padding: 5px 20px;
  display: block;
}
.sidebar-wrapper #faq li a img {
  max-width: 20px;
}
.sidebar-wrapper #faq li a:hover {
  background-color: var(--light-gray);
}

.bd-callout-warning {
  border-left-color: #f0ad4e;
}

.bd-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: 0.25rem;
  border-radius: 0.25rem;
  border-left-color: #f0ad4e;
}

.fs16{
  font-size: 16px;
}

.highlight pre code {
  font-size: inherit;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
code {
	font-size: 87.5%;
	color: #e83e8c;
	word-break: break-word;
	background: #ffeef6;
	padding: 6px;
	border-radius: 5px;
  display: inline-block;
  line-height: 1;
}
.title_code {
	font-size: 1em;
	color: #f76e2b;
	word-break: break-word;
	background: #ffe8d8;
	padding: 6px;
	border-radius: 5px;
	display: inline-block;
	line-height: 1;
}
.docsDetailsWrap h6 {
	font-size: 14px;
	background: var(--bs-secondary-bg);
	color: var(--bs-indigo);
	padding: 5px 10px;
	display: inline-block;
	border-radius: 4px;
	font-family: monospace;
}
.docsDetailsWrap p {
	color: var(--light-text);
}
.bd-clipboard+.highlight {
  margin-top: 0;
}

::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0px !important;
  text-align: start !important;
  text-align-last: start !important;
}

.bullets{
  list-style-type: disc;
}

.progress-steps > div {
  margin-left: 39px;
  position: relative;
}
.progress-steps > div.hidden {
  display: none;
}
.progress-steps > div:last-child > h1::before {
  border: none;
}
.progress-steps > div > h1 {
  font-size: 1em;
  margin-left: -39px;
}
.progress-steps > div > h1 > .numbers {
  background: var(--gray);
  color: var(--white-color);
}
.progress-steps > div > h1::before {
  content: "";
  position: absolute;
  border-left: dashed 1px var(--gray);
  width: 1px;
  height: calc(100% - 27px);
  top: 39px;
  left: -22.5px;
}
.progress-steps > div > h1 > .numbers {
  border-radius: 50%;
  width: 33px;
  height: 33px;
  display: inline-block;
  text-align: center;
  line-height: 33px;
  margin-right: 10px;
}