.line{
    line-height: 45px;
}
.bg{
    background-color: var(--label-color);
}

.bulkheightTable {
    height: calc(100vh - 276px);
}
.radio_tabs {
	border: 0 none;
	display: flex;
	flex-direction: column;
	width: 30%;
}
.radio_tabs.nav-tabs li.nav-item  {
	width: 100%;
	min-width: 180px;
	margin-bottom: 0;
	margin-right: -1px;
}
.radio_tabs input[type="radio"] + label {
	width: 100%;
	display: flex;
	align-items: center;
	transition: all 0.4s ease;
	padding: 15px 15px 15px 35px !important;
	background-color: var(--lightest-gray);
	color: var(--gray);
	border-left: 4px solid  var(--light-gray);
	border-top: 1px solid var(--light-gray);
	border-bottom: 1px solid var(--light-gray);
	border-right: 1px solid var(--primary);
}

.radio_tabs input[type="radio"] + label::before {
	top: calc(50% - 7px);
	left: 12px;
}
.radio_tabs input[type="radio"] + label::after {
	top: calc(50% - 4px);
	left: 15px;
}
.radio_tabs input[type="radio"].active + label::before {
	background: var(--primary);
	border-color: var(--primary);
}
.radio_tabs input[type="radio"].active + label {
	color: var(--primary);
	background-color: var(--white);
	border-color: var(--primary);
	border-right: 1px solid var(--white);
}
.radio_tabs + #myTabContent {
	padding: 30px;
	width: 70%;
	border: 1px solid var(--primary);
	margin-left: -1px;
	min-height: 230px;
	border-radius: 0 4px 4px 4px;
}
#myTabContent .input-group .form-control {
	border-radius: 0px;
	background-color: var(--white);
	color: var(--black) !important;
}