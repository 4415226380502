.line {
    line-height: 45px;
}

.bg {
    background-color: var(--lightest-secondary);
}

.fs14 {
    font-size: 14px;
}

.bg-mono{
    background-color: var(--primary);
}

.bg-monoz-light{
    background-color: var(--accordian-bg);
    border: 2px solid var(--accordian-border);
    color: var(--black);
}

.accordion-button:not(.collapsed) {
    background-color: var(--white);
    color: var(--black);
}

.accordion-button:focus {
    z-index: 3;
    border-color: var(--accordian-border);
    outline: 0;
    box-shadow: 0 0 0 0.25rem var(--shadow-color);
}

.group_name{
    border: 1px solid var(--primary-color);
}

.bg_slide.panel {
	background-color: var(--white);
	height: 100vh;
}
.group_detailsWrapper {
	height: calc(100vh - 106px);
	overflow: scroll;
}
.bg_slide .row.bb h2 {
	line-height: 1;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
}
.bg_slide .row.bb h2.smallFont {
    font-size: 11px;
}
.accordion-body p,
.bg_slide .row p {
    word-wrap: break-word;
    word-break: break-all;
    max-width: 100%;
}
.bg_slide .is_blocked p {
    max-width: 100%;
}
.panel{
    box-shadow: 20px 20px 50px var(--gray);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden;
}

.bb{
    border-bottom: 2px solid var(--card-border);
}

.tableDevices_group {
    height: calc(100vh - 142px);
    border: 0 none;
}
.table-disabled td {
	opacity: 0.3;
	background: var(--light-gray);
}
.chart{
    height: 50%;
}

.data{
    background-color: var(--lightest-gray);
    border-bottom: 2px solid var(--card-border);
    font-family: inherit;
}

.table_items_list td {
	max-width: 200px;
	word-break: break-all;
	word-wrap: break-word;
	text-overflow: ellipsis;
	overflow: hidden;
    min-height: 27px;
}

.accordion-header.position-relative.blocked-group{
    opacity: 0.3;
}