.order_details_panel .p3 {
    overflow-y: scroll;
}
.order_details_panel .bb {
    color: var(--white-color);
    background-color: var(--primary-color);
    border-bottom: 0 none;
}
.bg_slide.order_details_panel  .row.bb h2 {
    color: var(--white-color);
    font-size: 21px;
}
.order_panel_title {
    color: var(--white-color);
    background-color: var(--primary-color);
    padding: 0 1rem 1rem;
}
.order_panel_title p {
    color: var(--white-color);
}
.order_panel_title p.text-muted {
    color: var(--white-color) !important;
    opacity: 0.7;
}
.table.order_panel_table th { 
    background-color: var(--secondary);
    color: var(--white-color);
}
.table.order_panel_table td {
    padding: 15px 10px;
}
.order_panel_detail {
    height: calc(100vh - 216px);
    overflow: scroll;
}
.order_panel_table  {
    height: auto;
}
.card.mt-3.panelOpen .orders_list_table th:nth-child(2),
.card.mt-3.panelOpen .orders_list_table td:nth-child(2),
.card.mt-3.panelOpen .orders_list_table th:nth-child(5),
.card.mt-3.panelOpen .orders_list_table td:nth-child(5),
.card.mt-3.panelOpen .orders_list_table th:nth-child(3),
.card.mt-3.panelOpen .orders_list_table td:nth-child(3) {
    display: none;
}