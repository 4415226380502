.footerbody{
    height: 23px;
    position: fixed;
    z-index: 1001;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: var(--border-color);
    color: var(--text-color);
}

.connection {
    color: var(--black);
    font-size: 12px;
    font-weight: 600;
    line-height: 25px;
}

.b_right{
    border-right: 1px solid;
}

.spinner {
    width: 14px;
    height: 14px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #333333;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-top: 5px;
}
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  