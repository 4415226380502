.otp {
    width: 18%;
}

.showHidePassForget {
    position: absolute;
    top: 15px;
    right: 25px
}

.forgetPassHeading{
    font-size: 28px;
}

.back_to_login{
    cursor: pointer;
    text-align: center;
}