.canvasjs-chart-toolbar button {
	background-color:rgba(243, 152, 16, 1) !important;
	padding: 9px !important;
	border-radius: 50%;
	width: 30px !important;
	height: 30px !important;
	margin-top: -7px;
}
.canvasjs-chart-toolbar button:not(:hover) img { 
	opacity: 0.5;
}
.ipadchart:first-child .canvasjs-chart-toolbar button{
	background-color: #fff5e5 !important;
}
.ipadchart:first-child .canvasjs-chart-toolbar button:hover {
	background-color: rgba(243, 152, 16, 1) !important;
}