.pricingWrapper {
    margin-top: 20px;
}
.count_card {
    opacity: 0.8;
    filter: brightness(0) invert(0.6);
    width: 30px;
    margin-right: 15px;
}
.DeviceLineWrap {
    max-width: 770px;
    margin: 0 auto;
}
.scrolable-card {
    max-height: calc(100vh - 160px);
    overflow-y: scroll;
}
@media (min-width: 1800px) {    
    .col-xxl-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
        max-width: 100%;
    }
    .col-xxl-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
        max-width: 100%;
    }
    .col-xxl-6 {
        flex: 0 0 auto;
        width: 50%;
        max-width: 100%;
    }
    .col-xxl-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
        max-width: 100%;
    }
    .col-xxl-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
        max-width: 100;
    }
}