.tenant-alert {
	font-size: 0.9rem;
	padding: 7px 10px;
	border-radius: 30px;
	min-width: 160px;
	text-align: left;
    display: inline-flex;
    align-items: center;
    width: auto;
}
.tenant-alert .fas {
	font-size: 1.2rem;
	margin-right: 10px;
}
.tenant-contact-icon {
    font-size: 1.4rem;
    color: var(--white-color);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--primary);
    text-align: center;
    line-height: 50px;
}