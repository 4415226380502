.addDeviceTitle {
	font-size: 16px;
	font-weight: 300;
	margin-bottom: 10px;
	display: block;
	letter-spacing: 1px;
}

/* Example Styles for React Tags*/
.app {
	padding: 40px;
	text-align: center;
}

/* Example Styles for React Tags*/

.container {
	margin: auto;
	width: 50%;
}

.ReactTags__tags {
	position: relative;
}

/* Styles for the input */
.ReactTags__tagInput {
	width: 100%;
	border-radius: 2px;
	display: inline-block;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
	min-height: 38px;
	border-radius: 4px;
	padding: 5px 10px;
	background: var(--white);
	border: 1px solid var(--input-border);
	font-size: 14px;
	display: block;
	width: 100%;
	line-height: 1.5;
	color: var(--text-color);
	transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField::placeholder {
	color:var(--text-color);
	display: block;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
	border: 1px solid var(--light-primary);
	background: var(--lightest-primary);
	color: var(--primary-color);
	font-size: 14px;
	display: inline-block;
	padding: 8px;
	padding-top: 3px;
    padding-bottom: 3px;
	margin-top:8px;
	margin-left: 1px;
	border-radius: 4px;
}

.ReactTags__selected .ReactTags__remove {
	color: var(--gray);
	margin-left: 5px;
	cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
	position: absolute;
}

.ReactTags__suggestions ul {
	list-style-type: none;
	box-shadow: 0.05em 0.01em 0.5em var(--shadow-color);
	background: white;
	width: 200px;
}

.ReactTags__suggestions li {
	border-bottom: 1px solid var(--input-border);
	padding: 5px 10px;
	margin: 0;
}

.ReactTags__suggestions li mark {
	text-decoration: underline;
	background: none;
	font-weight: 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
	background: var(--lightest-primary);
	cursor: pointer;
}

.ReactTags__remove {
	border: none;
	cursor: pointer;
	background: none;
	color: var(--white);
}