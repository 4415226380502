.section-header h3 {
    font-weight: 700;
    font-size: 28px;
    line-height: 1.2;
}
.knowledge-container {
    max-width: 800px;
    margin: 0 auto;
}
.knowledge-item {
	background: var(--white);
	margin-bottom: 30px;
	padding: 40px 30px;
	border-radius: 10px;
    display: flex;
    cursor: pointer;
}
.knowledge-item .knowledge-thumb {
	width: 65px;
}
.knowledge-item .knowledge-thumb img {
	max-width: 100%;
}
.knowledge-item .knowledge-content {
	padding-left: 30px;
	width: calc(100% - 65px);
}
.knowledge-item .knowledge-content .title {
	margin: 0;
	margin-bottom: 4px;
    font-size: 20px;
    line-height: 1.2;
}
.knowledge-item .knowledge-content p {
    color: var(--gray);
    margin: 0;
}
.knowledge-item .knowledge-content .title a {
    color: var(--text-color);
}