.invoice-menu.correction-modal .modal-dialog.modal-dialog-centered {
    max-width: 950px;
    width: 900px;
}

.invoice_card {
    position: relative;
    overflow: hidden;
    padding: 20px 20px 60px;
    border:1px solid var(--border-color);
    border-left: 3px solid var(--secondary);
    border-bottom: 3px solid var(--secondary);
    height: 100%;
}
.invoice_card::after,
.invoice_card::before {
	background-color: var(--primary);
	content: '';
	width: 9px;
	height: 111px;
	transform: skewY(30deg);
	position: absolute;
	right: 0;
	top: -8px;
	transform-origin: bottom left;
    z-index: 3;
}
.invoice_card::after {
    top: auto;
	left: 0;
	bottom: -8px;
}

.invoice-footer {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 20px;
}

.invoice-logo {
    display: block;
    max-width: 50px;
}
.invoice-table {
	margin-top: 10px;
	height: auto;
	border-top: 2px solid var(--gray);
	border-bottom: 2px solid var(--gray);
}
.invoice-table th {
    padding:  10px 5px;
}
.invoice-table td {
	padding: 3px 5px;
}
.invoice-table td:first-child {
    max-width: 180px;
    white-space: break-spaces;
}
.blank_holder_lg {
    background-color: var(--border-color);
    border-radius: 3px;
    width: 160px;
    height: 32px;
    display: inline-block;
    margin-left: auto;
    vertical-align: middle;
    padding: 0;
}
.table > :not(caption) > * > .blank_holder,
.blank_holder {
    background-color: var(--border-color);
    border-radius: 3px;
    margin-top: 4px;
    width: 100px;
    height: 25px;
    display: inline-block;
    margin-left: auto;
    vertical-align: middle;
    padding: 0;
}
.table .blank_holder {
    width: 40px;
}
.table > :not(caption) > * > .value_holder,
.value_holder {
    width: 110px;
    height: 25px;
    margin-top: 4px;
    display: inline-block;
    margin-left: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
    padding: 0;
}
.table .value_holder {
    width: 75px;
}
.table .value_holder.amount {
    width: 106px;
}
.invoice_details {
	color: var(--gray);
	font-size: 0.75rem;
    word-wrap: break-word;
}
.invoice_name {
	margin: 0;
	line-height: 1;
}